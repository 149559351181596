/* fallback */
@font-face {
	font-family: 'Material Icons';
	font-style: normal;
	font-weight: 400;
	font-display: block;
	src: url(/assets/materialicons/google-material-icons.woff2) format('woff2') // output path to be used
}

/***
 * Currently only using these icons:
 * access_time, account_circle, add, block, cancel, close, flag, group,
 * group_add, group_work, home, mail_outline, more_vert, not_interested,
 * person, person_add, people, photo, share, smartphone, stars, vpn_key
 */

.material-icons {
	font-family: 'Material Icons';
	font-weight: normal;
	font-style: normal;
	font-size: 24px;
	line-height: 1;
	letter-spacing: normal;
	text-transform: none;
	display: inline-block;
	white-space: nowrap;
	word-wrap: normal;
	direction: ltr;
	font-feature-settings: 'liga';
	-webkit-font-feature-settings: 'liga';
	-webkit-font-smoothing: antialiased;
}
