/* You can add global styles to this file, and also import other style files */
@import './style-vars.scss';

/* @import '@angular/material/prebuilt-themes/deeppurple-amber.css'; */
@import url(public/materialicons/google-material-icons.scss);
/* @import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,400italic); */

body {
	margin: 0;
	font-family: Roboto, 'Helvetica Neue', Arial, sans-serif;
	font-display: swap;
	overscroll-behavior: contain; /* Prevents pull-to-refresh https://stackoverflow.com/a/47709903/2173380 */
}

@media (min-width: $min-big-screen) {
	::-webkit-scrollbar {
		width: 6px;
	}

	::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	}

	::-webkit-scrollbar-thumb {
	  background-color: $baseDarkColor;
	  outline: 1px solid slategrey;
	}
}

.handler {
	height: 100%;
	width: 100%;
	display: flex;
	flex-flow: column nowrap;
}
.handler-content{
	height: 100%;
	-webkit-overflow-scrolling: touch; // smooth scrolling (only iOS)
}

.h-spacer {
	flex: 1 1 auto;
}

.hidden {
	display: none;
}

a {
	color: $baseDarkColor;
	text-decoration: none;
}

.filtered-list-controllers {
	text-align: center;
	padding: 10px 0px;
	position: fixed;
	width: 100%;
	z-index: 5;
	.content {
		background-color: white;
		border-radius: 5px;
		right: 0px;
		padding-left: 10px;
		display: inline-block;
	}
}
$filters-upper-room: 56px;
.filtered-list-items-container {
	overflow-y: auto;
	overflow-x: hidden;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
	padding: 5px;
	&.has-filters {
		padding-top: $filters-upper-room;
	}
}
.empty-container {
	margin: 15px;
	border: 4px dashed lightgrey;
	padding: 15px;
	border-radius: 15px;
	&.has-filters {
		margin-top: $filters-upper-room;
	}
}

.moderate-card-title {
	font-size: 1em !important;
	font-weight: 400 !important;
}
.mat-mdc-card-actions {
	.mat-mdc-button {
		text-transform: uppercase;
	}
}

.spinner-container {
	display: flex;
    margin: 25px 0;
    justify-content: center;
}

.has-action-button {
	@media (max-width: $limit-small-screen) {
		padding-bottom: 2em;
	}
}

/*** FORMS : START ***/

.form-content {
	display: flex;
	flex-flow: row wrap;
	.hint-count {
		white-space: nowrap;
	}
	.form-block {
		display: inline-flex;
		flex-flow: row wrap;
		&.block-full-width {
			width: 100%;
			.form-full-width {
				flex: 1 1 100%;
			}
		}
	}
	.form-field {
		flex: 1 1 auto;
		margin: auto 5px;
		&:not(.has-mat-form-field) {
			margin: 20px 5px;
		}
		&.form-full-width {
			flex: 1 1 100%;
		}
	}
}
.fake-label-container {
	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-start;
	align-items: baseline;
	.fake-label {
		transform: scale(0.8);
		transform-origin: 0;
		opacity: .6;
	}
}
.form-actions {
	display: flex;
	justify-content: space-evenly !important;
}
.form-actions button,
mat-dialog-actions button {
	text-transform: uppercase;
}

/*** FORMS : END ***/

.avatar {
	border-radius: 50% !important;
}

.settings-table {
	max-width: 600px;
	@media (min-width: $min-big-screen) {
		align-self: center;
    	min-width: 580px;
	}
}
